<template>
  <div class="">
      <headers />
    <div class="mb" style="padding:15px;padding-top: 90px !important;" >
  
      <div class="col-12">
         <div class="page-title-box"><h4 class="page-title"><div class="lang" v-html="lan.relocation_cases" ></div> </h4>
         <router-link to="/movingCasesList" class="btn btn-primary ml-sm-4">{{lan.history_leases_search}}</router-link>
        </div>
        <div class="tab-box" >
        <ul class="tab-head nav nav-tabs" style="margin-top:20px">
            
            <li v-if="code!=''" class="nav-item" data-id="tc2" @click="tab('tc2')" v-bind:class="[curindex=='tc2' ? 'nav-item active show':'nav-item']">
              <a data-toggle="tab"  class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.single_input" ></div></a>
            </li> 
            <li class="nav-item" data-id="tc1" @click="tab('tc1')" v-bind:class="[curindex=='tc1' ? 'nav-item active show':'nav-item']">
              <a data-toggle="tab"  class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.batch_upload" ></div></a>
            </li> 
          </ul>
        </div>
        <div class="tab-content" style="width:100%;background:#fff">
            <div class="embed-responsive-16by9 tab-pane show active tc1" id="tc1" v-if="curindex=='tc1'">
              <div class="col-lg-12">
                <div class="search-result-box card-box">
                  <div class="drop_area mg-b-20 disFlex align-center hor-center"  id="drop_areas" :style="{'border-color': (borderhover ? '#3d8cff':'#BBBBBB')}">
                    <div  style=""><div class="lang" v-html="lan.drag_or_drop_here"></div></div>
                    <div>
                      <div class="font-blue pos-r cur-hand mg-l-10" style="top: 12px;"><i class="el-icon-upload mg-r-5"></i><div class="lang" v-html="lan.upload_file"></div></div>
                    </div>
                     <input type="file" accept="application/xlsx" id="uploadfile" class="input-upload cur-hand"  @change ="uploadFile($event.target.files)" />
                  </div>
                <div class="fileHtml" v-if="fileData.length>0">
                    <ul class="filelist">
                      <li v-for = "(item,index) in fileData"  :key="index" class="mg-b-20">
                          <div class="file-card">
                          <!--  <p>  <img v-bind:src="item.Url" alt="" style="width: 300px;height: 300px;"></p>-->
                            <div class="disFlex align-center">
                              <div style="display: inline-block;float: left;"><i class="el-icon-document mg-r-10"></i>{{item.fileText}}</div>
                              <div class="mg-l-30 flex text-right mg-r-20"><span class="mg-l-15 font-blue cur-hand" @click="deleteFile(index)">删除</span></div>
                            </div>
                        </div>
                      <!-- <p><el-progress :percentage=item.progressStatl  status="success"></el-progress></p>-->
                      </li>
                    </ul>
                  </div>
              
                  <div class="col-12">
                    <div class="responsive-table-plugin">

                      <div class="table-rep-plugin">
                        <div class="table-responsive">
                          <div class="d-flex justify-content-center p-5" v-if="isLoading">
                            <div class="spinner-border m-12" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                          <table class="table table-striped focus-on" v-if="mapList.length>0">
                            <thead>
                              <tr>
                                <th v-for="tableHeader in tableHeaders" :key="tableHeader" :class="'table-row-' + tableHeader" >{{$language(tableHeader)}}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody v-if="mapList.length>0">
                              <tr v-for="(obj, idNum) in mapList" :key="idNum" :class="{ 'focused' : isFocus === idNum }" @click="toggleFocus(idNum)">
                                <td v-for="tableHeader in tableHeaders" :key="tableHeader" >
                                {{(obj[tableHeader]==undefined ?'':obj[tableHeader])}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="p-2">
                            <code v-if="mapList.length==0">{{error_msg}}</code>
                          </div>
                        </div> <!-- end .table-responsive -->
                      </div> <!-- end .table-rep-plugin-->
                    </div>
                    <div class="btn-bottom" v-if="mapList.length>0"> <button type="button" class="btn"  @click="updateCases"><div class="lang" v-html="lan.upload"></div></button>
                      <button type="button" class="btn " @click="deleteFile(0)"><div class="lang" v-html="lan.cannel"></div></button>
                    </div>
                  </div>
              
                </div>
              </div>
            </div>
            <div class="embed-responsive-16by9 tab-pane show active tc2" id="tc2" v-if="curindex=='tc2'">
              <div class="card-box mb-2" style="position:relative">
                <div class="col-12">
                  <div class="card-box">

                      <h4><div class="lang" v-html="lan.base_info"></div></h4>
                   
                    <div class="line"><div class="left-box"><div class="lang" v-html="lan.company_name"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input :class="{'error':ed[0]==1}" :placeholder="lan.company_name" @keyup="scompany" v-model="uinfo['テナント名']">
                      <div class="company_box" v-if="showcom">
                        <div class="d-flex justify-content-center p-5" v-if="isLoading2">
                          <div class="spinner-border m-12" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div class="ccbox" >
                            <div class="cline" v-if="companyList.length==0">{{error_msg}} </div>
                            <div class="cline" v-for="(dd,index) in companyList" :key="index" @click="changeCom(dd)">{{dd.corpname}},{{dd.Corp_Num}}</div>
                        </div>
        
                      </div>
                    </div> </div>
                    <div class="line"><div class="left-box"><div class="lang" v-html="lan.corp_num"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"> <input v-model="uinfo.CorpNum"  :placeholder="lan.corp_num"> </div> </div>


                    <div class="line"><div class="left-box"><div class="lang" v-html="lan.input_user"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input :class="{'error':ed[1]==1}" v-model="uinfo['入力者']"  :placeholder="lan.input_user"> </div> </div>
                    <div class="line"><div class="left-box"><div class="lang" v-html="lan.company_out"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input type="checkbox" value="外資" style="width:25px" v-model="uinfo['外資系企業']"  :placeholder="lan.company_out"> </div> </div>
                    
                    <div class="line"><div class="left-box"><div class="lang" v-html="lan.migration_date"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box">
                      <ndatepicker :ndate="'file_date_to'"
         :val="file_date_to" :format="'yyyy/MM'" :placeholder="'2020/01'" @changedate="changedate"  />
                      </div> </div>

                 
                    <hr>
                    <div class="line">
                    <div class="bleft">
                      <h4 class="title"><div class="lang" v-html="lan.move_after"></div></h4>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.building_name"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input :class="{'error':ed[3]==1}" v-model="uinfo['現_ビル名']" @keyup="sbuilding" :placeholder="lan.building_name">
                      <div class="company_box" v-if="showcombuild">
                        <div class="d-flex justify-content-center p-5" v-if="isLoading2">
                          <div class="spinner-border m-12" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div class="ccbox" >
                            <div class="cline" v-if="buildList.length==0">{{error_msg}} </div>
                            <div class="cline" v-for="(dd,index) in buildList" :key="index" @click="changeBuild(dd)">{{dd.BuildingName}},{{dd.PropertyCode}},{{dd['Address']}}</div>
                        </div>
                        
                              
                          
                      </div>
                      </div></div> 


                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.address"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input :class="{'error':ed[4]==1}" v-model="uinfo['現_所在地']"  :placeholder="lan.address"></div> </div>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.sub_areas"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input :class="{'error':ed[5]==1}" v-model="uinfo['現_ＯＭＲゾーン']"  :placeholder="lan.sub_areas"></div> </div>
                      
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.complated_year"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input :class="{'error':ed[6]==1}" v-model="uinfo['現_竣工']" :placeholder="lan.complated_year"></div></div> 
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.scale"></div></div><div class="right-box"><input type="text" v-model="uinfo['現_延床']"  :placeholder="lan.scale"></div> </div>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.all_floor"></div></div><div class="right-box"><input  v-model="uinfo['現_基準階']"  :placeholder="lan.all_floor"></div> </div>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.floor"></div></div><div class="right-box"><input  v-model="uinfo['現_入居階']"  :placeholder="lan.floor"></div> </div>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.area"></div></div><div class="right-box"><input  type="number" v-model="uinfo['現_使用面積']"  :placeholder="lan.area"></div> </div>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.rent_price"></div></div><div class="right-box"><input type="number" v-model="uinfo['現_賃料']"  :placeholder="lan.rent_price"></div> </div>
                      
                    </div>
                    <div class="bleft">
                       <h4 class="title"><div class="lang" v-html="lan.move_before"></div></h4>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.building_name"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input :class="{'error':ed[7]==1}" v-model="uinfo['移_ビル名']"  @keyup="sbuilding2" :placeholder="lan.building_name">
                      <div class="company_box" v-if="showcombuild2">
                        <div class="d-flex justify-content-center p-5" v-if="isLoading2">
                          <div class="spinner-border m-12" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div class="ccbox" >
                            <div class="cline" v-if="buildList.length==0">{{error_msg}} </div>
                            <div class="cline" v-for="(dd,index) in buildList" :key="index" @click="changeBuild2(dd)">{{dd.BuildingName}},{{dd.PropertyCode}},{{dd['Address']}}</div>
                        </div>
                        
                              
                          
                      </div>
                      </div></div> 
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.address"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input :class="{'error':ed[8]==1}" v-model="uinfo['移_所在地']"  :placeholder="lan.address"></div> </div>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.sub_areas"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input :class="{'error':ed[9]==1}" v-model="uinfo['移_ＯＭＲゾーン']"  :placeholder="lan.sub_areas"></div> </div>
                      
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.complated_year"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input :class="{'error':ed[10]==1}" v-model="uinfo['移_竣工']" :placeholder="lan.complated_year"></div></div> 
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.scale"></div></div><div class="right-box"><input type="text" v-model="uinfo['移_延床']"  :placeholder="lan.scale"></div> </div>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.all_floor"></div></div><div class="right-box"><input  v-model="uinfo['移_基準階']"  :placeholder="lan.all_floor"></div> </div>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.floor"></div></div><div class="right-box"><input  v-model="uinfo['移_入居階']"  :placeholder="lan.floor"></div> </div>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.area"></div></div><div class="right-box"><input  type="number" v-model="uinfo['移_使用面積']"  :placeholder="lan.area"></div> </div>
                      <div class="line"><div class="left-box"><div class="lang" v-html="lan.rent_price"></div></div><div class="right-box"><input  type="number" v-model="uinfo['移_賃料']"  :placeholder="lan.rent_price"></div> </div>
                      
                    </div>  
                    </div>
                    <hr>
                    <h4><div class="lang" v-html="lan.reson"></div></h4>
                    <div class="line"><div class="left-box"><div class="lang" v-html="lan.reson"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><textarea :class="{'error':ed[11]==1}" :placeholder="lan.reson" v-model=" uinfo['移転理由']" style="width:500px;" ></textarea></div> </div>
                    <hr>
                    <div class="btn-bottom" > <button type="button" class="btn"  @click="updateCasesOne"><div class="lang" v-html="lan.save"></div></button>
                      <button type="button" class="btn " @click="cannel()"><div class="lang" v-html="lan.cannel"></div></button>
                    </div>
                  </div>
                </div>
            
              </div>
            </div>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>

import Headers from '@/components/Headers.vue'
import router from '@/router'
import axios from 'axios';
import { inject } from 'vue';
import Ndatepicker from '@/components/Ndatepicker.vue'
//import { MarkerClusterer } from "@googlemaps/markerclusterer";
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "h+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt))
fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o){
    if (new RegExp("(" + k + ")").test(fmt)) {
fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
}
    }
    return fmt;
}
export default {
  name: "search_result",
   components: {
    Headers,
    Ndatepicker
  },
  data() {
    const { currentRoute } = router
    return {
      center: { lat: Number, lng: Number },
      address: '',
      distance:20000,
      type:currentRoute.value.query.type==undefined ? "company":currentRoute.value.query.type,
      mapList:[],
      reload: 0,
      cad:'',
      file_date_to:'',

      map:null,
      uinfo:{
'テナント名':'',
'CorpNum':'',
'入力日':'',
'入力者':localStorage.getItem('email',''),
'外資系企業':false,
'現_物件番号':'',
'現_ＯＭＲゾーン':'',
'現_ビル名':'',
'現_所在地':'',
'現_竣工':'',
'現_延床':'',
'現_基準階':'',
'現_入居階':'',
'現_使用面積':'',
'現_賃料':'',
'移_物件番号':'',
'移_ＯＭＲゾーン':'',
'移_ビル名':'',
'移_所在地':'',
'移_竣工':'',
'移_延床':'',
"移_基準階":'',
"移_入居階": '',
"移_使用面積": "",
'移_賃料':'',
"移転時期": "",
'移転理由':''
      },
      ed:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      markers:[],
      infowindow:[],
      curindex:"tc2",
      tableHeaders:[],
      // company
       viewParams: {
        name: 'CorpReference',
        apiUrl: '/v1/corps_reference/',
        articleView: 'CorpReferenceArticle',
        articleParamId: 'id',
        dataType:100,
      },
      offset: 0,
      listStrage: {
        query:'',
        limit: 100,
        paged: 1,
      },
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object]
      },
      typeList:[],
      listData: [],
      isFocus: -1,
      ddMenu: false,
      jsonErr: false,
      isLoading: false,
      companyList:[],
      buildList:[],
      showcombuild:0,
      showcombuild2:0,
      isLoading2: false,
      showcom:false,
      downloadAlert: '',
      
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      cur_lan:'jp',
       suc_msg:"",
      error_msg:"",

      filepond:{},
      tag_input:'',
    
      fileData:[],
      finfo:{
        tags:[],
        name:'',
        remark:'',
        fid:1},
      borderhover:false,
    };
  },
  mounted() {
    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
    this.lan['required']='*';
    this.getTypeList();
  },
  methods: {
    changedate:function(e,id){
    if(id=='file_date_to'){
      this.uinfo['移転時期']=e;
    }
   
   },
    changeCom(item){
      this.uinfo['テナント名']=item.corpname;
      this.uinfo['CorpNum']=item.Corp_Num;
      this.comapnyList=[];
      this.showcom=false;
    },
    changeBuild(item){
      this.uinfo['現_物件番号']=item.PropertyCode;
      this.uinfo['現_ＯＭＲゾーン']=item.area;
      this.uinfo['現_ビル名']=item.BuildingName;
      this.uinfo['現_所在地']=item.Address;
      this.uinfo['現_竣工']=item.CompletionDate!=null && item.CompletionDate!='' ? item.CompletionDate.substr(0,10).replace("-","").replace("-","").replace("/",""):'';
      this.uinfo['現_延床']=item.TotalGFA;
      this.uinfo['現_基準階']=item.Typical_Minimum_FloorSize;
      
      this.comapnyList=[];
      this.showcombuild=false;
    },
    changeBuild2(item){
      this.uinfo['移_物件番号']=item.PropertyCode;
      this.uinfo['移_ＯＭＲゾーン']=item.area;
      this.uinfo['移_ビル名']=item.BuildingName;
      this.uinfo['移_所在地']=item.Address;
      this.uinfo['移_竣工']=item.CompletionDate!=null && item.CompletionDate!='' ? item.CompletionDate.substr(0,10).replace("-","").replace("-","").replace("/",""):'';
      this.uinfo['移_延床']=item.TotalGFA;
      this.uinfo['移_基準階']=item.Typical_Minimum_FloorSize;
      this.comapnyList=[];
      this.showcombuild2=false;
    },
    check_empty(){
      var check_feilds=['テナント名',
'入力者',
'移転時期',
'現_ビル名',
'現_所在地',
'現_ＯＭＲゾーン',
'現_竣工',
'移_ビル名',
'移_所在地',
'移_ＯＭＲゾーン',
'移_竣工',
"移転時期",
"移転理由"
];
     
      let status=0;
      this.ed=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
       if(this.uinfo['CorpNum']==''){
         this.uinfo['テナント名']='';
         this.ed[0]=1;
          status=1;
      }
      for(var d in check_feilds){
        if(this.uinfo[check_feilds[d]]==null || this.uinfo[check_feilds[d]]=='' || this.uinfo[check_feilds[d]]==' '){
          status=1;
          this.ed[d]=1;
        }
      }
      
      
      return status;
    },
    tab:function(tid){
      this.curindex=tid;
      if(tid=='tc1'){
         setTimeout(function(){
          var dropbox = document.getElementById('drop_areas');
            console.log(dropbox)
            dropbox.addEventListener("drop",this.enentDrop,false)
            dropbox.addEventListener("dragleave",function () {
              
              this.borderhover =  false;
            })
            dropbox.addEventListener("dragenter",function () {
            
              this.borderhover =  true;
            })
            dropbox.addEventListener("dragover",function () {
            
              this.borderhover =  true
            })
        },1500);
      }
    },
     enentDrop: function(e){
        this.borderhover = false
        e.stopPropagation();
        e.preventDefault();  //必填字段
        let fileData = e.dataTransfer.files;
        console.log(fileData);
        this.uploadFile(fileData)
      },
      uploadFile: function (e){   //渲染上传文件
      var file=e;
      console.log(file);
        for (let i = 0; i !== file.length; i++) {
          let fileJson = {
            Url:'',
            progressStatl:0,
            fileText:'',
          };
          let video_type= file[i].name.indexOf(".xls")>0;
           //如果是图片
            let fileurl = window.URL.createObjectURL(file[i]); //创建一个url连接,供src属性引用
            fileJson.Url = fileurl;
          if(!video_type){
              alert("Can not support file,only xls")
              return false;
             
          }
          fileJson.fileText = file[i].name;
          this.finfo.name=file[i].name;
   
          this.fileData=[fileJson];
          this.filepond=file[0];
          this.SubmitFile();
        }
      },
   deleteFile:function (index){   //删除已选文件
          this.fileData.splice(index,1);
          this.filepond=null;
          if(document.getElementById('uploadfile').value!=undefined){
            document.getElementById('uploadfile').value=null;
          }
          this.mapList=[];
      },
     toggleDDMenu: function() {
      this.ddMenu = !this.ddMenu
    },
   
    toggleFocus: function(idNum) {
      this.isFocus = idNum;
    },
    scompany: function(){
    
     if(this.uinfo['テナント名'].length>2){
     var that=this;
     this.isLoading2=true;
     var param={name:this.uinfo['テナント名'],id_token:localStorage.getItem('id_token')}
     that.companyList=[];
     that.showcom=true;
      axios.post(process.env.VUE_APP_API2_URL_BASE+'/getNameByCompany', param)
      .then(function (response) {
            that.isLoading2=false;
            if(response.data.status){
              that.companyList=response.data.data;
              if(that.companyList.length==0){
                that.error_msg=that.lan.empty_data;
              }
            }else{
              that.error_msg=response.data.msg;
            }
      })
      .catch(function (error) {
            console.log(error);
      });
     }else{
       this.showcom=false;
       this.isLoading2=false;
     }
    },
    getTypeList: function(){
      var that=this;
      this.isLoading=true;
      var param={id_token:localStorage.getItem('id_token')}
      axios.post(process.env.VUE_APP_API2_URL_BASE+'/getTypeList', param)
      .then(function (response) {
            that.isLoading=false;
            if(response.data.status){
              that.typeList=response.data.data;
            }else{
              that.error_msg=response.data.msg;
            }
      })
      .catch(function (error) {
            console.log(error);
      });
    
    },
    sbuilding: function(){
    
     if(this.uinfo['現_ビル名'].length>2){
     var that=this;
     this.isLoading2=true;
     var param={name:this.uinfo['現_ビル名'],id_token:localStorage.getItem('id_token')}
     that.buildList=[];
     that.showcombuild=true;
      axios.post(process.env.VUE_APP_API2_URL_BASE+'/getNameByBuilding', param)
      .then(function (response) {
            that.isLoading2=false;
            if(response.data.status){
              that.buildList=response.data.data;
              if(that.buildList.length==0){
                that.error_msg=that.lan.empty_data;
              }
            }else{
              that.error_msg=response.data.msg;
            }
      })
      .catch(function (error) {
            console.log(error);
      });
     }else{
       this.showcombuild=false;
       this.isLoading2=false;
     }
    },
    sbuilding2: function(){
    
     if(this.uinfo['移_ビル名'].length>2){
     var that=this;
     this.isLoading2=true;
     var param={name:this.uinfo['移_ビル名'],id_token:localStorage.getItem('id_token')}
     that.buildList=[];
     that.showcombuild2=true;
      axios.post(process.env.VUE_APP_API2_URL_BASE+'/getNameByBuilding', param)
      .then(function (response) {
            that.isLoading2=false;
            if(response.data.status){
              that.buildList=response.data.data;
              if(that.buildList.length==0){
                that.error_msg=that.lan.empty_data;
              }
            }else{
              that.error_msg=response.data.msg;
            }
      })
      .catch(function (error) {
            console.log(error);
      });
     }else{
       this.showcombuild2=false;
       this.isLoading2=false;
     }
    },
    SubmitFile:function (){
     
      let param = new FormData(); // 创建form对象
      if(this.fid==0){
      if(this.filepond==null || this.filepond.name==undefined ){
          this.error_msg="Pleace upload file"
          return false;
        }
      }
     
      param.append('filepond', this.filepond); 
       param.append('id_token', localStorage.getItem('id_token'));
      this.isLoading=true;
      var that=this;
      axios.post(process.env.VUE_APP_API2_URL_BASE+'/uploadCasesByXlsx', param,{headers:{'Content-Type': 'multipart/form-data'}})
      .then(function (response) {
        that.isLoading=false;
   
            if(response.data.status){
              that.tableHeaders=response.data.headers;
              that.mapList=response.data.data;
            
            }else{
              that.error_msg=response.data.msg;
            }
      })
      .catch(function (error) {
            console.log(error);
      });
    },
    updateCases:function (){
     
      let param = new FormData(); // 创建form对象
  
      param.append('email', localStorage.getItem('email','admin@cbre.com')); 
      param.append('data', JSON.stringify(this.mapList)); 
       param.append('id_token', localStorage.getItem('id_token'));
      this.isLoading=true;
      var that=this;
      axios.post(process.env.VUE_APP_API2_URL_BASE+'/updateCases', param,{headers:{'Content-Type': 'multipart/form-data'}})
      .then(function (response) {
        that.isLoading=false;
            console.log(response);
            if(response.data.status){
              that.mapList=[]
             that.deleteFile(0);
             alert(that.lan.upload_success)
              
            }else{
              that.error_msg=response.data.msg;
            
            }
      })
      .catch(function (error) {
            console.log(error);
      });
    },
    updateCasesOne:function (){
      if( this.check_empty()){
        return false;
      }
      let param = new FormData(); // 创建form对象
      this.uinfo['移転時期']=this.uinfo['移転時期'].Format("yyyy-MM-dd");
      this.uinfo['外資系企業']=this.uinfo['外資系企業']==true ?'外資系':''; 
      this.uinfo['現_竣工']=this.uinfo['現_竣工'].replace("/","").replace("-","").replace("年","").replace("月","")
      this.uinfo['移_竣工']=this.uinfo['移_竣工'].replace("/","").replace("-","").replace("年","").replace("月","")
      param.append('email', localStorage.getItem('email','admin@cbre.com')); 
      param.append('data', JSON.stringify([this.uinfo])); 
      param.append('id_token', localStorage.getItem('id_token'));
      this.isLoading=true;
      var that=this;
      axios.post(process.env.VUE_APP_API2_URL_BASE+'/updateCases', param,{headers:{'Content-Type': 'multipart/form-data'}})
      .then(function (response) {
        that.isLoading=false;
        console.log(response.data)
            if(response.data.status){
             alert(that.lan.upload_success)
             location.href='/#/movingCasesList';
            }else{
              that.error_msg=response.data.msg;
            }
      })
      .catch(function (error) {
            console.log(error);
      });
    },
   
  },
  watch: {

    $route(){
      
    },
    
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.google {
  height: 800px;
}
.overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 60px;
  line-height: 80px;
  opacity: 0.8;
  background: #4477aa;
  border: solid 3px #336699;
  border-radius: 4px;
  box-shadow: 2px 2px 10px #333;
  text-shadow: 1px 1px 1px #666;
  padding: 0 4px;
}
.overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}
.overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #336699;
}
.overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #336699;
}
.row {
  font-size: 14px;
}
pre {
  font-family: 'Ubuntu Mono';
  font-size: 14px;
}
.row {
  zoom: 1;
  margin-left: -20px;
}
.row:before,
.row:after {
  display: table;
  content: "";
  zoom: 1;
}
.row:after {
  clear: both;
}
.row>[class*="span"] {
  display: inline;
  float: left;
  margin-left: 20px;
}
.span1 {
  width: 40px;
}
.span2 {
  width: 100px;
}
.span3 {
  width: 160px;
}
.span4 {
  width: 220px;
}
.span5 {
  width: 280px;
}
.span6 {
  width: 340px;
}
.span7 {
  width: 400px;
}
.span8 {
  width: 460px;
}
.span9 {
  width: 520px;
}
.span10 {
  width: 580px;
}
.span11 {
  width: 640px;
}
.span12 {
  width: 700px;
}
.span13 {
  width: 760px;
}
.span14 {
  width: 820px;
}
.span15 {
  width: 880px;
}
.span16 {
  width: 940px;
}
.span17 {
  width: 1000px;
}
.span18 {
  width: 1060px;
}
.span19 {
  width: 1120px;
}
.span20 {
  width: 1180px;
}
.span21 {
  width: 1240px;
}
.span22 {
  width: 1300px;
}
.span23 {
  width: 1360px;
}
.span24 {
  width: 1420px;
}
pre.prettyprint {
  background: #fff;
  border-color: #c1c1c1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 10px #999;
  -moz-box-shadow: 0 0 10px #999;
  box-shadow: 0 0 10px #999;
  padding: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.label {
  font-size: 10.998px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999999;
  margin: 2px 6px;
}
.label {
  padding: 1px 4px 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.notice {
  background-color: #3a87ad;
}
form input[type=text] {
  border: solid 1px #999999;
  padding: 2px;
}
.edit_marker {
  width: 250px;
  height: 80px;
}
.edit_marker p {
  margin: 0;
}
.address_list {position:absolute; top:80px;left:30px;z-index: 999;background: #FFF;max-height: 500px;overflow-y: auto;}
.address_list .line{border-top:1px solid #eee;padding: 5px;margin:0 5px;     cursor: pointer;}
.address_list .line:nth-child(0){
  border-top:0;
}
.address_list .active{background: #003f2d; color:#fff;}
.nav-tabs li{cursor: pointer;}

.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {

  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: .75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: .5em;
    width: 100%;
  }

}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: .15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -.5rem;
  top: -.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}
.required{color:#ff0000;}
.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {

  .dropdown-menu.show li {
    flex-basis: 50%;
  }

}

@media screen and (max-width: 500px) {

  .dropdown-menu.show li {
    flex-basis: 100%;
  }

}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover, table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: .35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: .15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}
.table td .table-data-container{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}
.types{display: table;margin: 0 auto;}
.drop_area{width: 100%;
    text-align: center;
    position: relative;
    display: inline-block;
    height: 150px; 
    line-height: 150px;
    border: 1px #ced4da dashed;}
    .drop_area div{display: inline-block;}
    .input-upload{position:absolute;top:0;left:0;opacity: 0;width: 100%;height: 100%;}
    .font-blue{color: #012a2d;}
    .cur-hand{cursor: pointer;}
    .filelist{
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin-top: 10px;
    }
    .filelist li{list-style: none;}
.line{margin:5px 1%;width: 98%; display: inline-block;}
.line .left-box{font-weight: bolder;}
.line input{width: 300px;}
.left-box{width: 120px;float: left;}
.title,h4{color:#003f2d;}
.right-box{margin-left:3%;width: 60%; float: left;position:relative;}
.content{background: #fff;padding: 20px;position: relative;}
.btn{color:#fff;background: #042a2d;margin:10px 20px;}
.btn-bottom{margin:50px auto 0 auto;}
.bleft{width: 48%;margin:1%; float: left; display: inline-block;}
.company_box{    position: absolute;
    z-index: 999;
    top: 26px;
    left: 0;
    height: 260px;
    border: 1px solid #ccc;
    background: #fff;
    width: 550px;}
.company_box .cline{width: 98%;margin:0 1%; line-height: 25px; cursor: pointer; border-top:1px solid #ccc ;}
.company_box .cline:nth-child(1){border: 0;}
.company_box .cline:hover{color: #003f2d;}
.error{border:1px solid #ff0000}
</style>
